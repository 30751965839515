import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "react-bootstrap/Tooltip";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/loading";
import { useNavigate, useParams } from "react-router-dom";
import { createWorkerFactory } from "@shopify/react-web-worker";
import GradingAndRefurbUIComponent from "../../components/gradingandrefurb/refurb-steps/refurb-parent";
import { useContext, useEffect, useState } from "react";
import { CatalogItem, InventoryItem } from "../../external";
import { UserAuthenticatedContext } from "../../components/profile";
import { AxiosError } from "axios";
import {
  getCompletedStepsLocalStorageKey,
  getSelectedIndexLocalStorageKey,
  loadGradingAndRefurbStateIntoLocalStorage,
  useReadAllGradingAndRefurbState,
} from "../../components/gradingandrefurb/refurb-steps/persist-helpers";
import { ArrowReturnLeft, CheckCircle, Floppy2 } from "react-bootstrap-icons";
import { useDebounceCallback, useInterval, useLocalStorage } from "usehooks-ts";
import { GradingStepsConfiguration } from "../../components/gradingandrefurb/types";
import { getConfiguration } from "../../workers/grading";

const createInventoryItemsWorker = createWorkerFactory(
  () => import("../../workers/inventory")
);
const createCatalogWorker = createWorkerFactory(
  () => import("../../workers/catalog")
);

const saveIntervalMs = 10000;

const GradingAndRefurbPage = (): JSX.Element => {
  const navigate = useNavigate();
  const inventoryWorker = createInventoryItemsWorker();
  const catalogItemWorker = createCatalogWorker();

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [isPersisting, setIsPersisting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [inventoryItem, setInventoryItem] = useState<InventoryItem>();
  const [catalogItem, setCatalogItem] = useState<CatalogItem>();
  const [lastUpdated, setLastUpdated] = useState<Date>(new Date());

  const { itemId, gradingAndRefurbId } = useParams();
  if (!gradingAndRefurbId || !itemId) {
    throw new Error("No IDs provided");
  }

  const [selectedIndex, setSelectedIndex] = useLocalStorage<number>(
    getSelectedIndexLocalStorageKey(gradingAndRefurbId),
    0
  );
  const [completedSteps, setCompletedSteps] = useLocalStorage<Array<number>>(
    getCompletedStepsLocalStorageKey(gradingAndRefurbId),
    []
  );

  const [hasLoadedInitialState, setHasLoadedInitialState] =
    useState<boolean>(false);

  const [gradingConfiguration, setGradingConfiguration] =
    useState<GradingStepsConfiguration>();

  const currentState = useReadAllGradingAndRefurbState(gradingAndRefurbId);
  const [lastPersistedState, setLastPersistedState] = useState<
    Record<string, any>
  >(currentState !== null ? currentState : {});
  const [stateChanged, setStateChanged] = useState<boolean>(false);

  const [isRetrievingInitialState, setIsRetrievingInitialState] =
    useState<boolean>(false);

  // Load initial data items
  useEffect(() => {
    if (isRetrievingInitialState) return;
    const retrieveData = async () => {
      try {
        const inventoryRetrieveResult = await inventoryWorker.getInventoryItem(
          itemId,
          userAuthenticatedContext.token!
        );
        setInventoryItem(inventoryRetrieveResult);
        const catalogRetrieveResult = await catalogItemWorker.getCatalogItem(
          userAuthenticatedContext.token!,
          inventoryRetrieveResult.catalogItemId!
        );
        setCatalogItem(catalogRetrieveResult);
        const gradingRetrieveResult = await inventoryWorker.getGradingById(
          itemId,
          gradingAndRefurbId,
          userAuthenticatedContext.token!
        );
        loadGradingAndRefurbStateIntoLocalStorage(
          gradingAndRefurbId,
          gradingRetrieveResult.results ?? {}
        );
        const configResult = await getConfiguration(
          userAuthenticatedContext.token!,
          gradingRetrieveResult.refurbDefinition?.criteria?.clientCode,
          gradingRetrieveResult.refurbDefinition?.criteria?.sku
        );
        setGradingConfiguration(configResult.configuration);
        if (gradingRetrieveResult.currentStepIndex) {
          setSelectedIndex(gradingRetrieveResult.currentStepIndex);
        }
        if (gradingRetrieveResult.completedSteps) {
          setCompletedSteps(gradingRetrieveResult.completedSteps);
        }
        setHasLoadedInitialState(true);
      } catch (e) {
        console.error(e);
        setErrorMessage(e instanceof AxiosError ? e.message : "Unknown error");
      } finally {
        setHasLoaded(true);
        setIsRetrievingInitialState(false);
      }
    };

    setErrorMessage("");
    if (
      userAuthenticatedContext &&
      userAuthenticatedContext.token &&
      itemId &&
      gradingAndRefurbId &&
      !hasLoaded
    ) {
      setIsRetrievingInitialState(true);
      retrieveData();
    }
  }, [
    catalogItemWorker,
    gradingAndRefurbId,
    hasLoaded,
    inventoryWorker,
    isRetrievingInitialState,
    itemId,
    setCompletedSteps,
    setSelectedIndex,
    userAuthenticatedContext,
  ]);

  // UseDebounceCallback reduces the likelihood of slowing the page down
  // if the state changes a lot in short amount time by limiting updates
  const updateStateChanged = useDebounceCallback(() => {
    const stateChanged =
      JSON.stringify(lastPersistedState) !== JSON.stringify(currentState);
    console.info("State changed", stateChanged);
    setStateChanged(stateChanged);
  }, 500);

  // Save the state every interval seconds
  useInterval(() => {
    persistCurrentStateIfChanged();
  }, saveIntervalMs);

  useEffect(() => {
    updateStateChanged();
  }, [currentState, updateStateChanged]);

  const persistCurrentStateIfChanged = (completed = false): Promise<void> =>
    new Promise((resolve, reject) => {
      console.info("persisting");
      if (
        !userAuthenticatedContext ||
        !userAuthenticatedContext.token ||
        !itemId ||
        !gradingAndRefurbId ||
        !catalogItem ||
        !inventoryItem
      ) {
        reject("Invalid state");
        return;
      }
      if (!currentState) {
        console.info("Skipping persist as currentState is not valid");
        resolve();
        return;
      }
      if (!stateChanged && !completed) {
        console.info(
          "Skipping persist as state has not changed, and we are not completing"
        );
        resolve();
        return;
      }

      setLastPersistedState(currentState);
      if (isPersisting) {
        console.warn("Not persisting as already in progress");
        resolve();
        return;
      }
      setIsPersisting(true);
      inventoryWorker
        .postGradingAndRefurb(
          inventoryItem.id,
          gradingAndRefurbId,
          {
            results: currentState,
            completedSteps: completedSteps,
            currentStepIndex: selectedIndex,
            completed,
            refurbDefinition: {
              criteria: gradingConfiguration?.gradeCriteria!,
              version: gradingConfiguration?.configurationVersion!,
            },
          },
          userAuthenticatedContext.token
        )
        .then(() => {
          setLastUpdated(new Date());
          resolve();
        })
        .catch((e) => {
          reject(e);
        })
        .finally(() => {
          setIsPersisting(false);
          setStateChanged(false);
        });
    });

  const completeGradingAndRefurb = () => {
    console.info("completeGradingAndRefurb");
    persistCurrentStateIfChanged(true)
      .then(() => {
        navigate(`/inventory?itemId=${inventoryItem!.id}`);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const [hasFinishedLoading, setHasFinishedLoading] = useState<boolean>(false);
  useEffect(() => {
    const allTrue = [
      catalogItem,
      inventoryItem,
      gradingConfiguration,
      hasLoadedInitialState,
    ].every((v) => !!v === true);
    if (allTrue) {
      setTimeout(() => {
        setHasFinishedLoading(true);
      }, 500);
    } else {
      setHasFinishedLoading(false);
    }
  }, [hasLoadedInitialState, catalogItem, inventoryItem, gradingConfiguration]);

  if (!itemId || !gradingAndRefurbId) {
    return <div>Invalid URL</div>;
  }

  const LoadingCompleteCheck = () => (
    <CheckCircle color="green" size={20} style={{ margin: "0.5em" }} />
  );
  const LoadingSpinner = () => (
    <Spinner
      size="sm"
      style={{ marginLeft: "0.6em", marginRight: "0.6em", marginTop: "0.3em" }}
    />
  );

  if (errorMessage) {
    return (
      <Container>
        <Alert variant="danger">{errorMessage}</Alert>
      </Container>
    );
  }

  const GradingLoadingProgress = () => (
    <Container>
      <Row>
        <Col>
          {inventoryItem ? <LoadingCompleteCheck /> : <LoadingSpinner />}
          Inventory Item
        </Col>
      </Row>
      <Row>
        <Col>
          {catalogItem ? <LoadingCompleteCheck /> : <LoadingSpinner />}
          Catalogue Item
        </Col>
      </Row>
      <Row>
        <Col>
          {gradingConfiguration ? <LoadingCompleteCheck /> : <LoadingSpinner />}
          Grading Configuration
        </Col>
      </Row>
      <Row>
        <Col>
          {hasLoadedInitialState ? (
            <LoadingCompleteCheck />
          ) : (
            <LoadingSpinner />
          )}
          Other
        </Col>
      </Row>
    </Container>
  );
  const shouldShowLoadingProgress =
    !catalogItem ||
    !inventoryItem ||
    !gradingConfiguration ||
    !hasLoadedInitialState ||
    !hasFinishedLoading;

  return (
    <Container>
      <Row>
        <Alert
          variant="dark"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            style={{ marginRight: "1em" }}
            variant="secondary"
            onClick={() => navigate(`/inventory?itemId=${itemId}`)}
          >
            <ArrowReturnLeft /> Return to Inventory
          </Button>
          <div style={{ textAlign: "right", flex: 1 }}>
            Last Saved -{" "}
            {lastUpdated ? lastUpdated.toLocaleTimeString() : "Never"}
            <OverlayTrigger
              overlay={
                <Tooltip id="unsavedWarning">
                  Unsaved changes. Autosave will save automatically.
                </Tooltip>
              }
            >
              <Button
                disabled={isPersisting || !stateChanged}
                style={{ marginLeft: "1em" }}
                onClick={() => persistCurrentStateIfChanged()}
              >
                Save
                {isPersisting ? (
                  <Spinner
                    as={"span"}
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    size="sm"
                    style={{ marginLeft: "0.5em" }}
                  />
                ) : (
                  <Floppy2
                    color={stateChanged ? "coral" : "white"}
                    style={{ marginLeft: "0.5em" }}
                    aria-hidden="true"
                  />
                )}
              </Button>
            </OverlayTrigger>
          </div>
        </Alert>
      </Row>
      <Row>
        <Col>
          {shouldShowLoadingProgress ? (
            <GradingLoadingProgress />
          ) : (
            <GradingAndRefurbUIComponent
              config={gradingConfiguration}
              inventoryId={itemId}
              refurbIdentifier={gradingAndRefurbId}
              catalogItem={catalogItem}
              completeGradingAndRefurb={completeGradingAndRefurb}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(GradingAndRefurbPage, {
  onRedirecting: () => <Loading />,
});
