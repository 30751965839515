import { BaseGradingComponentProps } from "./types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { useGradingAndRefurbState } from "./persist-helpers";
import { useEffect } from "react";

export interface FreeTextComponentProps {
  title: string;
  subtitle?: string;
}

const FreeTextComponent = ({
  refurbIdentifier,
  setStepCompleted,
  stepOutputKey,
  title,
  subtitle,
}: BaseGradingComponentProps & FreeTextComponentProps): JSX.Element => {
  const [inputText, setInputText] = useGradingAndRefurbState<string>(
    stepOutputKey,
    refurbIdentifier
  );

  // Set step completed immediately
  useEffect(() => {
    setStepCompleted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <h3>{title}</h3>
      <Row>
        <Col>
          <Form.Group>
            {subtitle ? <Form.Label>{subtitle}</Form.Label> : null}
            <Form.Control
              as="textarea"
              rows={8}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

export default FreeTextComponent;
