import Handlebars from "handlebars";
import QRCode from "qrcode";
import "./printLabel.css";
import { retrieveTemplate } from "./retrieveTemplate";

export interface CreateItemLabelOptions {
  width?: number;
  imageType?: "image/png" | "image/jpeg" | "image/webp";
  data: CreateItemLabelHTMLProps;
}

export interface CreateItemLabelHTMLProps {
  currentBoxNumber: number;
  boxCount: number;
  pmid?: string;
  inventoryId: string;
  packerName: string;
  packedDateTime: string;
  palletRef: string;
  sku: string;
  productListingTitle: string;
  grade: string;
  comments?: string;
  imageUrls?: string[];
}

export const CreateItemLabelHTML = async ({
  width = 150,
  imageType = "image/png",
  data: {
    currentBoxNumber,
    boxCount,
    pmid,
    packerName,
    packedDateTime,
    palletRef,
    sku,
    productListingTitle,
    grade,
    comments,
    imageUrls,
    inventoryId,
  },
}: CreateItemLabelOptions) => {
  const template = await retrieveTemplate("/templates/inventory-label.hbs");
  const generateQR = async (text: string) =>
    await QRCode.toDataURL(text, {
      errorCorrectionLevel: "Q",
      width,
      type: imageType,
    });

  const errors: string[] = [];

  if (boxCount < 1) {
    errors.push("Unknown number of labels/boxes.");
  }

  if (pmid === "") {
    errors.push("Unknown PMID");
  }

  if (packedDateTime === "") {
    errors.push("Unknown time of packing");
  }

  if (palletRef === "") {
    errors.push(
      "Unknown pallet number. Item must have a pallet number for label printing to function."
    );
  }

  if (sku === "") {
    errors.push(
      "Final SKU not allocated, check Retail exit shows and that all entries are complete"
    );
  }

  let extraLineContent = "";
  // TODO: Swoon specific logic here when necessary
  // if (swoonBTS) {
  //   const newSku = getSwoonBTSSKUMapping(originalSku).trim();
  //   if (newSku !== "") {
  //     extraLineContent = `Almost Perfect ${newSku}`;
  //   } else {
  //     console.log("No new SKU found for " + originalSku);
  //   }
  // }

  if (errors.length > 0) {
    throw new Error(errors.join("\n"));
  }

  let qrCodePMID;
  if (pmid) {
    qrCodePMID = await generateQR(pmid);
  }
  const qrCodeInventoryId = await generateQR(inventoryId);

  const data = {
    boxCount,
    currentBoxNumber,
    pmid: pmid,
    inventoryId,
    qrCodePMIDContents: qrCodePMID,
    qrCodeInventoryIdContents: qrCodeInventoryId,
    packerName,
    packedDateTime,
    palletRef,
    sku,
    productListingTitle,
    comments,
    imageUrls,
    extraLineContent,
    grade,
  };

  const compiledTemplate = Handlebars.compile(template);
  const renderedTemplate = compiledTemplate(data);
  return renderedTemplate;
};
