import { createWorkerFactory } from "@shopify/react-web-worker";
import { CatalogItem } from "../../../external";
import {
  GradingStepsConfiguration,
  GradingStepType,
} from "../../../external/inventory-component/configuration/types";

const createGradingWorker = createWorkerFactory(
  () => import("../../../workers/grading")
);

/**
 * Retrieves the correct grading steps configuration.
 * @param userToken The user token to retrieve the grading configuration
 * @param catalogItem The catalog item
 * @returns the relevant configuration
 */
export const getGradingAndRefurbStepsConfiguration = async (
  userToken: string,
  catalogItem: CatalogItem
): Promise<GradingStepsConfiguration> => {
  const gradingWorker = createGradingWorker();
  return (
    await gradingWorker.getConfiguration(
      userToken,
      catalogItem.clientId,
      catalogItem.itemId
    )
  ).configuration;
};

export const DefaultTestRefurbStepsConfiguration = (
  catalogItem: CatalogItem
): GradingStepsConfiguration => ({
  configurationVersion: 1,
  gradeCriteria: {
    isDefault: true,
  },
  steps: [
    {
      stepType: GradingStepType.CatalogueCheck,
      stepOutputKey: "catalogueCheck",
      title: "Catalogue Check",
    },
    {
      stepType: GradingStepType.BoxCount,
      stepOutputKey: "inboundBoxCount",
      variant: "inbound",
      title: "Inbound Box Count",
    },
    {
      stepType: GradingStepType.SingleChoice,
      title: "Reason for return",
      subtitle: "Please determine the reason for return.",
      options: [
        { label: "Transit Damage", value: "Transit Damage" },
        { label: "Manufacturing Defect", value: "Manufacturing Defect" },
        { label: "Customer Error", value: "Customer Error" },
        { label: "Incomplete", value: "Incomplete" },
        {
          label: "Other",
          value: "Other",
        },
      ],
      stepOutputKey: "causeOfReturn",
    },
    {
      stepType: GradingStepType.SingleChoice,
      title: "Grade",
      subtitle: "Please select the relevant grade.",
      options: [
        {
          description: "Mint condition, exactly as new but opened box.",
          value: "A+",
          label: "A+",
        },
        {
          description:
            "Item as good as new, perhaps previously assembled with minimal signs of prior use.",
          value: "A",
          label: "A",
        },
        {
          description:
            "Item used once or twice, but in excellent condition. Up to 1-2 light scuffs, marks, scratches or dents.",
          value: "B+",
          label: "B+",
        },
        {
          description:
            "Item used previously. Very good condition with 1-2 moderate scuffs, marks, scratches or dents.",
          value: "B",
          label: "B",
        },
        {
          description:
            "Equivalent to A grade, but comments are required. For example, a sofa that is complete but has a scatter cushion that doesn't colour match.",
          value: "AC",
          label: "AC",
        },
        {
          description:
            "Equivalent to B grade, but comments are required. For example, there are parts missing that do not affect the functionality.",
          value: "BC",
          label: "BC",
        },
        {
          description:
            "Good stock but not identifiable, so will go to auction.",
          value: "C1",
          label: "C1",
        },
        {
          description:
            "Needs work to be retail ready - good for hobbyist to repair or upcycle.",
          value: "C2",
          label: "C2",
        },
        {
          description:
            "Needs a lot of work to bring to retail ready. Good for a commercial outfit to repair or reuse.",
          value: "C3",
          label: "C3",
        },
        {
          description:
            "Beyond economical repair. Item is incomplete with no chance of spares, or requires repairs but would take too long to do those repairs.",
          value: "BER",
          label: "BER",
        },
      ],
      stepOutputKey: "grade",
    },
    {
      stepType: GradingStepType.DetailedInformation,
      stepOutputKey: "incomingDamageHandlebars",
      title: "Handlebars",
      configuration: {
        sections: [
          {
            sectionName: "Handlebars",
            options: [
              {
                optionName: "Scratch",
                optionType: "count",
                optionImageRequired: true,
                optionKey: "scratch",
                countOptions: [
                  {
                    displayName: "Minor",
                    resultKey: "minor",
                  },
                  {
                    displayName: "Major",
                    resultKey: "major",
                  },
                ],
              },
              {
                optionName: "Dent",
                optionType: "count",
                optionKey: "dent",
                optionImageRequired: true,
                countOptions: [
                  {
                    displayName: "Minor",
                    resultKey: "minor",
                  },
                  {
                    displayName: "Major",
                    resultKey: "major",
                  },
                ],
              },
              {
                optionName: "Scuffed",
                optionType: "count",
                optionKey: "scuffed",
                optionImageRequired: true,
                countOptions: [
                  {
                    displayName: "Minor",
                    resultKey: "minor",
                  },
                  {
                    displayName: "Major",
                    resultKey: "Major",
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    {
      stepType: GradingStepType.FreeText,
      stepOutputKey: "comments",
      title: "Grading/Refurb comments",
      subtitle:
        "Please enter any comments relating to grading or refurbishment of this item.",
    },
    {
      stepType: GradingStepType.Image,
      stepOutputKey: "itemImage",
      title: "Image of item",
      description: "Please upload an image of the item.",
      isRequired: true,
    },
    {
      stepType: GradingStepType.Image,
      stepOutputKey: "labelImage",
      title: "Label image",
      description: "Please upload an image of the label if one is available",
      isRequired: false,
    },
    {
      stepType: GradingStepType.Count,
      stepOutputKey: "outboundBoxCount",
      title: "Outbound Box Count",
      subtitle: "Please specify the outbound box count for the item",
      desiredCount: 2,
      limit: 8,
    },
    {
      stepType: GradingStepType.Confirm,
      stepOutputKey: "piiRemovalConfirmed",
      description: "Has PII been removed from the item?",
      title: "PII Removal",
    },
    {
      stepType: GradingStepType.Complete,
      stepOutputKey: "complete",
      title: "Complete",
    },
  ],
});
