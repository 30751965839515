import { BaseGradingComponentProps } from "./types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { useGradingAndRefurbState } from "./persist-helpers";
import { useContext, useEffect, useState } from "react";
import { InventoryItem } from "../../../external/inventory-component/types";
import ImageUploadComponent from "../../inventory/image-upload-component";
import axios from "axios";
import { GenerateUploadImageUrlImage } from "../../../workers/inventory";
import { createWorkerFactory } from "@shopify/react-web-worker";
import { UserAuthenticatedContext } from "../../profile";
import { useCircleDarkMode } from "../../../hooks/useCircleDarkMode";
import InventoryImagesComponent from "../../inventory/inventory-images";
import Loading from "../../loading";
import { ArrowClockwise, Image } from "react-bootstrap-icons";
import { GradingStepImage } from "../../../external/inventory-component/configuration/types";

const createInventoryItemsWorker = createWorkerFactory(
  () => import("../../../workers/inventory")
);

export type ImageGradingStepComponentProps = BaseGradingComponentProps &
  Omit<GradingStepImage, "stepType"> & {
    inventoryId: string;
  };

const ImageGradingStepComponent = ({
  refurbIdentifier,
  setStepCompleted,
  stepOutputKey,
  title,
  subtitle,
  description,
  isRequired,
  inventoryId,
}: ImageGradingStepComponentProps): JSX.Element => {
  const [hasStoredImage, setHasStoredImage] = useGradingAndRefurbState<boolean>(
    stepOutputKey,
    refurbIdentifier
  );
  const [isPerformingServerAction, setIsPerformingServerAction] =
    useState(false);
  const inventoryWorker = createInventoryItemsWorker();
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const [inventoryItem, setInventoryItem] = useState<InventoryItem>();

  const [modalImageUploadShow, setModalImageUploadShow] = useState(false);
  const [isUploadingImages, setIsUploadingImages] = useState(false);
  const darkMode = useCircleDarkMode();
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (isPerformingServerAction) {
      return;
    }
    setStepCompleted(isRequired ? !!hasStoredImage : true);
  });

  const retrieveInventoryItem = () => {
    setIsPerformingServerAction(true);
    inventoryWorker
      .getInventoryItem(inventoryId, userAuthenticatedContext.token!)
      .then((inventoryItemFromServer) => {
        setInventoryItem(inventoryItemFromServer);
        setIsPerformingServerAction(false);
      })
      .catch((e) => {
        console.error("Error fetching inventory item", e);
        alert("Error fetching inventory item, please try refreshing the page");
      });
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL((file as any).preview));
    },
    [files]
  );

  // Retrieve the inventory item on load
  if (!isPerformingServerAction && !inventoryItem) {
    retrieveInventoryItem();
  }

  if (isPerformingServerAction) {
    return <Loading />;
  }

  return (
    <Container>
      <Row style={{ justifyContent: "space-between" }}>
        <Col>
          <h3>{title}</h3>
        </Col>
        <Col style={{ textAlign: "end" }}>
          <Button
            variant="secondary"
            onClick={() => {
              retrieveInventoryItem();
            }}
          >
            Refresh Images from Server
            <ArrowClockwise style={{ marginLeft: "0.5em" }} size={24} />
          </Button>
        </Col>
      </Row>
      <Row>{subtitle ? <h5>{subtitle}</h5> : null}</Row>
      <Row>{description ? <p>{subtitle}</p> : null}</Row>
      <Row>
        <Col>
          <Row>
            {!inventoryItem?.images || inventoryItem?.images.length === 0 ? (
              <b style={{ color: darkMode ? "lightcoral" : "coral" }}>
                No images found
              </b>
            ) : (
              <InventoryImagesComponent
                deleteImageFunction={(image) => {
                  return new Promise(() => {
                    inventoryWorker
                      .deleteImage(
                        userAuthenticatedContext.token!,
                        inventoryItem.id,
                        image.uniqueId
                      )
                      .then(() => {
                        // Set a timeout to allow the image to be deleted before refreshing
                        setTimeout(() => {
                          retrieveInventoryItem();
                        }, 1000);
                      });
                  });
                }}
                images={inventoryItem?.images ?? []}
              />
            )}
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Button
              variant="primary"
              style={{ marginTop: "0.5em", maxWidth: "300px" }}
              size="lg"
              onClick={() => {
                setModalImageUploadShow(true);
              }}
            >
              Upload Images <Image style={{ marginLeft: "0.5em" }} size={24} />
            </Button>
          </Row>
        </Col>
      </Row>
      <Modal
        show={modalImageUploadShow}
        dialogClassName="image-upload-modal"
        onHide={() => {
          // eslint-disable-next-line no-restricted-globals
          confirm(
            "Are you sure you want to close without saving the image(s)?"
          ) && setModalImageUploadShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageUploadComponent
            maxFiles={10}
            previewSizes={{
              xxl: "3",
              xl: "3",
              lg: "2",
              md: "1",
              sm: "1",
              xs: "1",
            }}
            onDrop={(files) => {
              setFiles(files);
            }}
            onRemoveFile={(file) => {
              setFiles(files.filter((f) => f !== file));
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={isUploadingImages}
            onClick={() => {
              // eslint-disable-next-line no-restricted-globals
              confirm(
                "Are you sure you want to close without saving the image(s)?"
              ) && setModalImageUploadShow(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="success"
            disabled={isUploadingImages}
            onClick={() => {
              setIsUploadingImages(true);
              const images: GenerateUploadImageUrlImage[] = files.map(
                (image) => ({
                  name: image.name,
                  imageSource: "grading",
                  comment: "",
                  gradingId: refurbIdentifier,
                })
              );
              inventoryWorker
                .getPresignedUrlForImageUploads(
                  userAuthenticatedContext.token!,
                  {
                    inventoryId,
                    images: images,
                  }
                )
                .then((result) => {
                  Promise.all(
                    result.data.presignedUrls.map((presignedUrl, i) =>
                      axios.put(presignedUrl.url, files[i])
                    )
                  ).then(() => {
                    setTimeout(() => {
                      setModalImageUploadShow(false);
                      setIsUploadingImages(false);
                      setHasStoredImage(true);

                      // Refresh the inventory item
                      retrieveInventoryItem();
                    }, 1000);
                  });
                })
                .catch((e) => {
                  console.error(e);
                });
            }}
          >
            Save Images
            {isUploadingImages ? (
              <Spinner style={{ marginLeft: "0.5em" }} size="sm" />
            ) : null}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ImageGradingStepComponent;
