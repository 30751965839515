import { useState, useEffect, useContext } from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import TaskItem from "../../components/task/task-item";
import Loading from "../../components/loading";
import { getAssignees, getTasks } from "../../workers/task";
import { Task } from "../../external/task-component/openapi";
import TaskSearch from "./task-search";
import PaginationComponent from "../../components/pagination";
import { Meta } from "../../external";
import { UserAuthenticatedContext } from "../../components/profile";

const TasksPage = (): JSX.Element => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [tasksLoaded, setTasksLoaded] = useState<boolean>(false);
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  // Pagination Section Start
  const defaultCurrentPage = 1;
  const defaultCount = 25;
  const [currentPage, setCurrentPage] = useState<number>(defaultCurrentPage);
  const [count, setCount] = useState<number>(defaultCount);
  const [meta, setMeta] = useState<Meta>({
    next: null,
    previous: null,
    current: 1,
    totalPages: 1,
    maxPerPage: 25,
    totalResults: 0,
  });
  // Pagination Section End
  const [assignees, setAssignees] = useState<{ email: string; name: string }[]>(
    []
  );
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<{ [key: string]: any }>({});

  const handleCurrentPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleCount = (count: number) => {
    setCount(count);
  };

  const handleCloseFilters = () => setShowFilters(false);
  const handleShowFilters = () => setShowFilters(true);

  const handleSearchFilters = (searchParams: { [key: string]: any }) => {
    setCurrentPage(defaultCurrentPage);
    setFilters(searchParams);
  };

  useEffect(() => {
    if (userAuthenticatedContext.token && filters !== {}) {
      setTasksLoaded(false);

      (async () => {
        const modifiedSearchParams =
          filters.type === "all" ? { ...filters, type: undefined } : filters;

        const tasks = await getTasks(
          {
            count: count,
            page: currentPage,
            ...modifiedSearchParams,
          },
          userAuthenticatedContext.token ?? ""
        );

        setTasks(tasks.data);
        setMeta(tasks.meta);

        const assigneesForDropdown = await getAssignees(
          userAuthenticatedContext.token ?? ""
        );

        setAssignees([
          ...assigneesForDropdown.data,
          { email: "", name: "Unassigned" },
        ]);
        setTasksLoaded(true);
      })();
    }
  }, [userAuthenticatedContext.token, filters, count, currentPage]);

  return (
    <Container style={{ width: "95%", minWidth: "95%" }}>
      <Row>
        <Col lg="3">
          <Offcanvas
            placement="start"
            responsive="lg"
            show={showFilters}
            onHide={handleCloseFilters}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Filters</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ justifyContent: "left" }}>
              <TaskSearch
                onSearch={handleSearchFilters}
                taskItemsLoaded={tasksLoaded}
                setTaskItemsLoaded={setTasksLoaded}
                assigneesList={assignees}
              />
            </Offcanvas.Body>
          </Offcanvas>
        </Col>
        <Col lg="9" style={{ overflowY: "auto", maxHeight: "100vh" }}>
          <Row className="align-items-center mb-1">
            <Col xs="auto">
              <h2>Tasks</h2>
            </Col>
            <Col xs="auto" className="ml-auto">
              <Button
                variant="success"
                onClick={handleShowFilters}
                className="d-lg-none"
              >
                Show Filters
              </Button>
            </Col>
          </Row>
          {!tasksLoaded ? (
            <Loading />
          ) : tasks.length === 0 ? (
            <Alert
              variant="light"
              className="text-center"
              style={{ marginTop: "20%" }}
            >
              <Alert.Heading>
                No tasks found matching your search criteria. Please modify your
                search and try again.
              </Alert.Heading>
            </Alert>
          ) : (
            tasks.map((task) => (
              <TaskItem key={task.taskId} task={task} assignees={assignees} />
            ))
          )}
          <Container>
            <PaginationComponent
              meta={meta}
              itemsLoaded={tasksLoaded}
              childToParentPages={handleCurrentPage}
              childToParentCount={handleCount}
            ></PaginationComponent>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(TasksPage, {
  onRedirecting: () => <Loading />,
});
