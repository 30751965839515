/*
 * - Step by step system
 * - Each step "manages" itself
 * - Step data is stored
 */

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BoxCountComponent from "./box-count";
import DetailedInformationComponent from "./damages";
import { useEffect, useState } from "react";
import {
  ArrowLeft,
  ArrowRight,
  CheckCircle,
  XCircle,
} from "react-bootstrap-icons";
import CompleteGradeComponent from "./complete-grade";
import { CatalogItem } from "../../../external";
import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import CatalogItemPreviewComponent from "./catalog-item-preview";
import { useLocalStorage } from "usehooks-ts";
import {
  getCompletedStepsLocalStorageKey,
  getSelectedIndexLocalStorageKey,
} from "./persist-helpers";
import { useDebugMode } from "../../../hooks/useDebugMode";
import FreeTextComponent from "./free-text";
import ConfirmComponent from "./confirm";
import ChoiceComponent from "./choice";
import CatalogueCheckComponent from "./catalogue-check";
import ImageGradingStepComponent from "./image";
import {
  GradingStepsConfiguration,
  GradingStepType,
  GradingStep,
} from "../../../external/inventory-component/configuration/types";
import CountComponent from "./count";

export interface GradingAndRefurbUIComponentProps {
  config: GradingStepsConfiguration;
  refurbIdentifier: string;
  inventoryId: string;
  catalogItem: CatalogItem;
  completeGradingAndRefurb: () => void;
}

// Set default context
export const GradingAndRefurbContext =
  React.createContext<GradingAndRefurbContextType>({
    catalogItem: undefined,
    inventoryId: undefined,
    refurbIdentifier: undefined,
  });

export type GradingAndRefurbContextType = {
  catalogItem?: CatalogItem;
  inventoryId?: string;
  refurbIdentifier?: string;
};

const GradingAndRefurbUIComponent = ({
  config,
  catalogItem,
  inventoryId,
  refurbIdentifier,
  completeGradingAndRefurb,
}: GradingAndRefurbUIComponentProps) => {
  const [selectedStepIndex, setSelectedStepIndex] = useLocalStorage<number>(
    getSelectedIndexLocalStorageKey(refurbIdentifier),
    0
  );
  const [completedSteps, setCompletedSteps] = useLocalStorage<Array<number>>(
    getCompletedStepsLocalStorageKey(refurbIdentifier),
    []
  );

  const [allStepsComplete, setAllStepsComplete] = useState<boolean>(
    completedSteps.length === config.steps.length
  );

  useEffect(() => {
    setAllStepsComplete(completedSteps.length === config.steps.length);
  }, [completedSteps.length, config.steps.length]);

  const isDebug = useDebugMode();
  const canForward = () =>
    selectedStepIndex < config.steps.length - 1 &&
    (completedSteps.includes(selectedStepIndex) || isDebug);

  const canBack = () => selectedStepIndex > 0;

  const goForward = () => {
    if (canForward()) setSelectedStepIndex(selectedStepIndex + 1);
  };

  const goBack = () => {
    if (canBack()) setSelectedStepIndex(selectedStepIndex - 1);
  };

  const [showItemDetails, setShowItemDetails] = useState(false);

  const handleClose = () => setShowItemDetails(false);
  const handleShow = () => setShowItemDetails(true);

  const buttonStyle = {
    width: "200px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  };

  // Invalid step configuration checks
  // - Check that the last step is GradingStepType.Complete
  if (
    config.steps[config.steps.length - 1].stepType !== GradingStepType.Complete
  ) {
    console.error(
      `The last step is not of type GradingStepType.Complete (currently '${
        config.steps[config.steps.length - 1].stepType
      }')`
    );
    console.table(config.steps);

    return (
      <Container>
        <h3>Invalid steps.</h3>
        <p>
          Steps must end with <code>GradingStepType.Complete</code>
        </p>
      </Container>
    );
  }

  // - Check that all stepOutputKey values are unique
  //     This is necessary to ensure we don't lose output values

  const stepOutputKeyValues = config.steps.map((s) => s.stepOutputKey);
  if (new Set(stepOutputKeyValues).size !== config.steps.length) {
    console.error(
      "There are stepOutputKey values with identical values in steps!"
    );
    console.table(stepOutputKeyValues);

    return (
      <Container>
        <h3>Invalid steps.</h3>
        <p>
          There are <code>stepOutputKey</code> values with identical values in
          steps!
        </p>
        <code>
          {stepOutputKeyValues.map((v, i) => (
            <div key={i}>
              {`${i}: ${v}`}
              <br />
            </div>
          ))}
        </code>
      </Container>
    );
  }

  // End invalid step configuration checks

  const getStepName = (step: GradingStep) => {
    switch (step.stepType) {
      case GradingStepType.BoxCount:
        return <span>Box Count ({step.variant})</span>;
      case GradingStepType.DetailedInformation:
        return (
          <span>Damages ({step.configuration.sections[0].sectionName})</span>
        );
      case GradingStepType.FreeText:
        return <span>{step.title}</span>;
      default:
        return <span>{step.title ?? step.stepType}</span>;
    }
  };

  const currentStep = config.steps[selectedStepIndex];
  const currentStepIsNotCatalogueCheck =
    currentStep.stepType !== GradingStepType.CatalogueCheck;
  const GradingAndRefurbNavbar = (
    <Navbar
      fixed="bottom"
      style={{
        backgroundColor: "white",
        flexDirection: "column",
        boxShadow: "0px 0px 15px 2px rgba(0,0,0,0.5)",
      }}
    >
      <Container style={{ flexDirection: "column" }}>
        <Row className="d-lg-none mb-2">
          <Col>
            <Button variant="success" size="lg" onClick={handleShow}>
              Show Item Details
            </Button>
          </Col>
        </Row>
        <Row className="w-100" xs={3}>
          <Col className="d-flex" style={{ justifyContent: "right" }}>
            <Button
              onClick={goBack}
              size="lg"
              style={buttonStyle}
              disabled={!canBack()}
              aria-label="Back"
            >
              <ArrowLeft size={24} className="mr-2" />
              <span className="d-none d-lg-block">Back</span>
            </Button>
          </Col>
          <Col className="d-flex" style={{ justifyContent: "center" }}>
            <DropdownButton
              id="steps-dropdown-button"
              variant="secondary"
              drop="up"
              size="lg"
              title={`Step ${selectedStepIndex + 1} of ${config.steps.length}`}
            >
              {config.steps.map((step, stepIndex) => {
                return (
                  <Dropdown.Item
                    key={`dropdown-${stepIndex}`}
                    onClick={() => setSelectedStepIndex(stepIndex)}
                    // Disabled if we haven't completed the step, and this step is not the one after the latest completed
                    disabled={
                      !isDebug &&
                      !completedSteps.includes(stepIndex) &&
                      completedSteps[completedSteps.length - 1] !==
                        stepIndex - 1
                    }
                  >
                    <span style={{ marginRight: "0.25em" }}>
                      {completedSteps.includes(stepIndex) ? (
                        <CheckCircle color="green" />
                      ) : (
                        <XCircle color="red" />
                      )}
                    </span>
                    Step {stepIndex + 1} - {getStepName(step)}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </Col>
          <Col className="d-flex" style={{ justifyContent: "left" }}>
            {allStepsComplete &&
            selectedStepIndex === config.steps.length - 1 ? (
              <Button
                size="lg"
                style={buttonStyle}
                onClick={completeGradingAndRefurb}
                variant="success"
              >
                Submit
              </Button>
            ) : (
              <Button
                onClick={goForward}
                size="lg"
                style={buttonStyle}
                disabled={!canForward()}
                aria-label="Forward"
              >
                <span className="d-none d-lg-block">Forward</span>
                <ArrowRight size={24} />
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </Navbar>
  );

  return (
    <GradingAndRefurbContext.Provider
      value={{
        catalogItem,
        inventoryId,
        refurbIdentifier,
      }}
    >
      <Container>
        <Row>
          {currentStepIsNotCatalogueCheck ? (
            <Col lg="4">
              <Offcanvas
                show={showItemDetails}
                placement="start"
                responsive="lg"
                onHide={handleClose}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Item Details</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body
                  className="d-flex"
                  style={{ justifyContent: "center" }}
                >
                  <CatalogItemPreviewComponent
                    catalogItem={catalogItem}
                  ></CatalogItemPreviewComponent>
                </Offcanvas.Body>
              </Offcanvas>
            </Col>
          ) : null}
          <Col lg={currentStepIsNotCatalogueCheck ? "8" : "12"}>
            <Container style={{ marginBottom: "50px" }}>
              {getComponentToRender(
                config,
                currentStep,
                refurbIdentifier,
                (complete: boolean) => {
                  if (complete && !completedSteps.includes(selectedStepIndex)) {
                    setCompletedSteps([...completedSteps, selectedStepIndex]);
                  }
                  if (!complete && completedSteps.includes(selectedStepIndex)) {
                    setCompletedSteps(
                      completedSteps.filter((s) => s !== selectedStepIndex)
                    );
                  }
                },
                catalogItem,
                inventoryId
              )}
            </Container>
          </Col>
          {GradingAndRefurbNavbar}
        </Row>
      </Container>
    </GradingAndRefurbContext.Provider>
  );
};

const getComponentToRender = (
  config: GradingStepsConfiguration,
  step: GradingStep,
  gradingId: string,
  setCompletedStep: (complete: boolean) => void,
  catalogItem: CatalogItem,
  inventoryId: string
): JSX.Element => {
  const stepOutputKey = step.stepOutputKey;

  const commonFields = {
    refurbIdentifier: gradingId,
    stepOutputKey,
    setStepCompleted: setCompletedStep,
    catalogItem,
  };

  const commonFieldsWithTextOverride = {
    ...commonFields,
    title: step.title,
    subtitle: step.subtitle,
  };

  switch (step.stepType) {
    case GradingStepType.CatalogueCheck:
      return <CatalogueCheckComponent {...commonFields} />;
    case GradingStepType.BoxCount:
      return (
        <BoxCountComponent
          {...commonFieldsWithTextOverride}
          variant={step.variant}
          desiredCount={step.desiredCount}
          limit={step.limit}
        />
      );
    case GradingStepType.Count:
      return (
        <CountComponent
          {...commonFieldsWithTextOverride}
          desiredCount={step.desiredCount}
          limit={step.limit}
          minimum={step.minimum}
        />
      );
    case GradingStepType.SingleChoice:
      return (
        <ChoiceComponent
          {...commonFieldsWithTextOverride}
          multiChoice={false}
          options={step.options}
        />
      );
    case GradingStepType.MultiChoice:
      return (
        <ChoiceComponent
          {...commonFieldsWithTextOverride}
          multiChoice={true}
          minSelections={step.minSelections}
          options={step.options}
        />
      );
    case GradingStepType.DetailedInformation:
      return (
        <DetailedInformationComponent
          {...commonFieldsWithTextOverride}
          configuration={step.configuration}
          stepType={GradingStepType.DetailedInformation}
        />
      );
    case GradingStepType.Complete:
      return (
        <CompleteGradeComponent
          {...commonFields}
          refurbConfiguration={config}
        />
      );
    case GradingStepType.FreeText:
      return <FreeTextComponent {...commonFieldsWithTextOverride} />;
    case GradingStepType.Confirm:
      return (
        <ConfirmComponent
          {...commonFieldsWithTextOverride}
          description={step.description ?? "No description provided"}
        />
      );
    case GradingStepType.Image:
      return (
        <ImageGradingStepComponent
          {...commonFieldsWithTextOverride}
          inventoryId={inventoryId}
          isRequired={step.isRequired}
        />
      );
  }

  // We shouldn't get here if all step types are accounted for
  return <code>Error - step type {step.stepType} is not configured</code>;
};

export default GradingAndRefurbUIComponent;
