import { BoxCountComponentProps } from "./types";
import CountComponent from "./count";

const BoxCountComponent = ({
  desiredCount,
  limit,
  stepOutputKey,
  variant,
  refurbIdentifier,
  setStepCompleted,
}: BoxCountComponentProps): JSX.Element => {
  const variantTitleText = () => {
    switch (variant) {
      case "inbound":
        return "Inbound";
      case "outbound":
        return "Outbound";
    }
  };
  return (
    <CountComponent
      title={`${variantTitleText()} Box Count`}
      subtitle={`Catalogue expects ${desiredCount} boxes`}
      limit={limit}
      stepOutputKey={stepOutputKey}
      refurbIdentifier={refurbIdentifier}
      setStepCompleted={setStepCompleted}
    />
  );
};

export default BoxCountComponent;
