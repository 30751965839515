import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { InventoryItem } from "../../external";

interface InventoryInboundCardProps {
  values: InventoryItem;
  onShowModal: (index: number) => void;
  onShowNotesModal: (index: number, data: InventoryItem) => void;
  onHandleConfirmArrival: (
    index: number,
    value: boolean,
    setFieldValue: any
  ) => void;
  index: number;
  disabled?: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  inboundProcessed: boolean;
}

const InventoryInboundCard: React.FC<InventoryInboundCardProps> = ({
  values,
  onShowModal,
  onShowNotesModal,
  onHandleConfirmArrival,
  index,
  disabled,
  setFieldValue,
  inboundProcessed,
}) => {
  const noImageAvailableUrl = "https://via.placeholder.com/150/?text=No+Images";

  const handleViewMore = () => {
    onShowModal(index);
  };

  const handleAddNotes = () => {
    onShowNotesModal(index, values);
  };

  return (
    <Col key={values.catalogItemId}>
      <Card className="mb-4">
        {values.imageLinks !== undefined && values.imageLinks.length > 0 ? (
          <Card.Img
            variant="top"
            src={values.imageLinks[0]}
            alt="Product Image"
            style={{ height: "300px", objectFit: "cover" }}
          />
        ) : (
          <Card.Img
            variant="top"
            src={noImageAvailableUrl}
            alt="Product Image"
            style={{ height: "300px", objectFit: "cover" }}
          />
        )}
        <Card.Body>
          <Card.Title>SKU: {values.catalogItemId}</Card.Title>
          <Card.Text>Pallet: {values.pallet}</Card.Text>
          <Card.Text>Quantity: {values.quantity}</Card.Text>
          <Button
            variant="primary"
            className="mb-2"
            onClick={handleViewMore}
            disabled={disabled}
            style={{ marginRight: "10px" }}
          >
            View More
          </Button>
          {!inboundProcessed ? (
            <>
              <Button
                disabled={disabled}
                variant="secondary"
                className="mb-2"
                onClick={handleAddNotes}
              >
                Add Notes
              </Button>
              <Form.Check
                disabled={disabled}
                type="checkbox"
                label="Confirm Arrival"
                onChange={async (event) => {
                  const value = event.target.checked;
                  onHandleConfirmArrival(index, value, setFieldValue);
                }}
              />
            </>
          ) : (
            <>
              <Card.Text>Date Confirmed: {values.arrivalDate}</Card.Text>
            </>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default InventoryInboundCard;
