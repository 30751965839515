import Table from "react-bootstrap/Table";
import {
  GradingStep,
  GradingStepBoxCount,
  GradingStepFreeText,
  GradingStepDetailedInformation,
  GradingStepType,
  GradingStepsConfiguration,
  GradingStepConfirm,
  GradingStepChoice,
  GradingStepMultiChoice,
  GradingStepImage,
  GradingStepCount,
} from "../../../external/inventory-component/configuration/types";
import { useReadGradingAndRefurbState } from "./persist-helpers";
import { DetailedInformationSectionOption } from "../../../external/inventory-component/configuration/detailedInformation";
import { InventoryItemStoredImage } from "../../../external/inventory-component/types";

export interface RefurbSummaryTableComponentProps {
  refurbConfiguration: GradingStepsConfiguration;
  refurbIdentifier: string;
}

export const GradingSummaryTable = ({
  refurbConfiguration,
  refurbIdentifier,
}: RefurbSummaryTableComponentProps) => {
  return (
    <Table bordered={true}>
      <thead>
        <tr>
          <th>Page</th>
          <th>Field</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {refurbConfiguration.steps
          .map((step, index) => (
            <CompletedRowComponent
              refurbIdentifier={refurbIdentifier}
              key={index}
              index={index}
              step={step}
            />
          ))
          .filter((v) => !!v)}
      </tbody>
    </Table>
  );
};

const CompletedRowComponent = ({
  refurbIdentifier,
  step,
  index: rowIndex,
}: {
  refurbIdentifier: string;
  step: GradingStep;
  index: number;
}) => {
  switch (step.stepType) {
    case GradingStepType.BoxCount:
      return (
        <BoxCountRow
          refurbIdentifier={refurbIdentifier}
          step={step}
          rowIndex={rowIndex}
        />
      );
    case GradingStepType.Count:
      return (
        <CountRow
          refurbIdentifier={refurbIdentifier}
          step={step}
          rowIndex={rowIndex}
        />
      );
    case GradingStepType.SingleChoice:
      return (
        <SingleChoiceRow
          refurbIdentifier={refurbIdentifier}
          step={step}
          rowIndex={rowIndex}
        />
      );
    case GradingStepType.MultiChoice:
      return (
        <MultiChoiceRow
          refurbIdentifier={refurbIdentifier}
          step={step}
          rowIndex={rowIndex}
        />
      );
    case GradingStepType.DetailedInformation:
      return (
        <DamagesRow
          refurbIdentifier={refurbIdentifier}
          step={step}
          rowIndex={rowIndex}
        />
      );
    case GradingStepType.CatalogueCheck:
      return (
        <tr>
          <td>{rowIndex + 1}</td>
          <td>Link Check</td>
          <td>Passed</td>
        </tr>
      );
    case GradingStepType.Complete:
      return <></>;
    case GradingStepType.FreeText:
      return (
        <FreeTextRow
          refurbIdentifier={refurbIdentifier}
          step={step}
          rowIndex={rowIndex}
        />
      );
    case GradingStepType.Confirm:
      return (
        <ConfirmRow
          refurbIdentifier={refurbIdentifier}
          step={step}
          rowIndex={rowIndex}
        />
      );
    case GradingStepType.Image:
      return (
        <ImageStepSummaryRow
          refurbIdentifier={refurbIdentifier}
          step={step}
          rowIndex={rowIndex}
        />
      );
    default:
      console.error("Unknown step type", step);
      return (
        <tr>
          <td>{rowIndex + 1}</td>
          <td>
            <code>Unsupported step type {step.stepType}</code>
          </td>
          <td></td>
        </tr>
      );
  }
};

type SummaryRowProps<T> = {
  refurbIdentifier: string;
  step: T;
  rowIndex: number;
};

const BoxCountRow = ({
  refurbIdentifier,
  step,
  rowIndex,
}: SummaryRowProps<GradingStepBoxCount>) => {
  const boxCount = useReadGradingAndRefurbState<number>(
    refurbIdentifier,
    step.stepOutputKey
  );

  return (
    <tr>
      <td>{rowIndex + 1}</td>
      <td>Box Count ({step.variant})</td>
      <td>{boxCount}</td>
    </tr>
  );
};

const CountRow = ({
  refurbIdentifier,
  step,
  rowIndex,
}: SummaryRowProps<GradingStepCount>) => {
  const count = useReadGradingAndRefurbState<number>(
    refurbIdentifier,
    step.stepOutputKey
  );

  return (
    <tr>
      <td>{rowIndex + 1}</td>
      <td>{step.title}</td>
      <td>{count}</td>
    </tr>
  );
};

const DamagesRow = ({
  refurbIdentifier,
  rowIndex,
  step,
}: SummaryRowProps<GradingStepDetailedInformation>) => {
  const damagesData = useReadGradingAndRefurbState<
    Record<string, Record<string, number | boolean>>
  >(refurbIdentifier, step.stepOutputKey);

  if (!damagesData) {
    let value = "Damages";
    if (
      step.configuration?.sections &&
      step.configuration?.sections.length > 0
    ) {
      value = `Damages (${step.configuration.sections[0].sectionName})`;
    }

    return (
      <tr>
        <td>{rowIndex + 1}</td>
        <td>{value}</td>
        <td>
          <i>No data available</i>
        </td>
      </tr>
    );
  }

  return (
    <>
      {step.configuration.sections.map((section, index) => (
        <tr key={index}>
          <td>{rowIndex + 1}</td>
          <td>Damages ({section.sectionName})</td>
          <td>
            {section.options.map((o, optionIndex) => (
              <DamageEntry
                key={`damageEntry-${optionIndex}`}
                damagesData={damagesData}
                o={o}
              />
            ))}
          </td>
        </tr>
      ))}
    </>
  );
};

const DamageEntry = ({
  o,
  damagesData,
}: {
  o: DetailedInformationSectionOption;
  damagesData: Record<string, Record<string, number | boolean>>;
}): JSX.Element => {
  if (o.optionType === "checkbox") {
    const value = damagesData[o.optionKey];

    return (
      <p>
        <b>{o.optionName}:</b> {value ? "Yes" : "No"}
      </p>
    );
  }

  if (!o.countOptions) {
    return <b>Invalid Configuration</b>;
  }

  return (
    <p>
      <b>{o.optionName}:</b>
      {o.countOptions.map((co, coIndex) => {
        if (damagesData[o.optionKey] === undefined) {
          return (
            <span key={coIndex}>
              <br />
              <i>{co.displayName}</i>: 0
            </span>
          );
        }

        return (
          (
            <span key={coIndex}>
              <br />
              <i>{co.displayName}</i>
              {": "}
              {damagesData[o.optionKey][co.resultKey] ?? "0"}
            </span>
          ) ?? (
            <span key={coIndex}>
              <br />
              <i>{co.displayName}</i>: 0
            </span>
          )
        );
      })}
    </p>
  );
};

const SingleChoiceRow = ({
  rowIndex,
  refurbIdentifier,
  step,
}: SummaryRowProps<GradingStepChoice>) => {
  const grade = useReadGradingAndRefurbState<string>(
    refurbIdentifier,
    step.stepOutputKey
  );
  return (
    <tr>
      <td>{rowIndex + 1}</td>
      <td>{step.title}</td>
      <td>
        <span>{grade}</span>
      </td>
    </tr>
  );
};
const MultiChoiceRow = ({
  rowIndex,
  refurbIdentifier,
  step,
}: SummaryRowProps<GradingStepMultiChoice>) => {
  const choices = useReadGradingAndRefurbState<string[]>(
    refurbIdentifier,
    step.stepOutputKey
  );
  return (
    <tr>
      <td>{rowIndex + 1}</td>
      <td>Choice</td>
      <td>
        <span>{choices?.join(", ")}</span>
      </td>
    </tr>
  );
};

const FreeTextRow = ({
  rowIndex,
  refurbIdentifier,
  step,
}: SummaryRowProps<GradingStepFreeText>) => {
  const inputValue = useReadGradingAndRefurbState<string>(
    refurbIdentifier,
    step.stepOutputKey
  );
  return (
    <tr>
      <td>{rowIndex + 1}</td>
      <td>{step.title}</td>
      <td>
        <span>{inputValue}</span>
      </td>
    </tr>
  );
};

const ConfirmRow = ({
  refurbIdentifier,
  step,
  rowIndex,
}: SummaryRowProps<GradingStepConfirm>) => {
  const isConfirmed = useReadGradingAndRefurbState<string>(
    refurbIdentifier,
    step.stepOutputKey
  );

  return (
    <tr>
      <td>{rowIndex + 1}</td>
      <td>{step.description}</td>
      <td>{isConfirmed ? "Confirmed" : ""}</td>
    </tr>
  );
};

const ImageStepSummaryRow = ({
  refurbIdentifier,
  step,
  rowIndex,
}: SummaryRowProps<GradingStepImage>) => {
  const storedImage = useReadGradingAndRefurbState<InventoryItemStoredImage>(
    refurbIdentifier,
    step.stepOutputKey
  );

  return (
    <tr>
      <td>{rowIndex + 1}</td>
      <td>{step.title}</td>
      <td>
        {storedImage ? (
          <img src={storedImage.imageUrl} alt={storedImage.name}></img>
        ) : (
          <i>No image uploaded</i>
        )}
      </td>
    </tr>
  );
};
