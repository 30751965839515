import { CatalogItem, Client } from "../../external";
import "../../App.scss";
import Table from "react-bootstrap/Table";
import CatalogRowComponent from "./catalog-row";
import Loading from "../loading";
import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import { UserAuthenticatedContext } from "../profile";
import { ErrorBoundary } from "react-error-boundary";
import { ExclamationCircle } from "react-bootstrap-icons";
import { Filters } from "../../workers/catalog";

export interface CatalogTableProps {
  catalogItems: CatalogItem[];
  catalogItemsLoaded: boolean;
  clients: Client[];
  filters: Filters;
  childToParentTableSort: (
    sortField: string,
    sortDirection: "asc" | "desc"
  ) => void;
}

const CatalogTableComponent = ({
  catalogItems,
  catalogItemsLoaded,
  clients,
  filters,
  childToParentTableSort,
}: CatalogTableProps): JSX.Element => {
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const [sortField, setSortField] = useState<string>(filters.sortField);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">(
    filters.sortDirection
  );

  const handleSort = (field: string) => {
    const newSortDirection =
      sortField === field && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(newSortDirection);
    childToParentTableSort(field, newSortDirection);
  };

  const headers = [
    { name: "Image", sortKey: null },
    { name: "Client ID", sortKey: "clientId.keyword" },
    { name: "Client SKU", sortKey: "clientItemId.keyword" },
    { name: "Name", sortKey: "name.keyword" },
    { name: "RRP", sortKey: "retailPricePence" },
    { name: "Links", sortKey: null },
    { name: "Last Updated", sortKey: "lastUpdatedTimestamp" },
  ];

  return (
    <ErrorBoundary
      fallbackRender={({ error, resetErrorBoundary }) => {
        return (
          <td>
            <ExclamationCircle
              style={{ marginBottom: "0.25em", marginRight: "0.25em" }}
              size={18}
              color="red"
            />
            Error occurred rendering table. Please share the below error with
            support.
            <hr />
            <pre>Error rendering CatalogTableComponent</pre>
            <pre>{error.message}</pre>
          </td>
        );
      }}
    >
      <Container style={{ width: "100%", minWidth: "100%", overflowX: "auto" }}>
        <Table>
          <thead>
            <tr style={{ fontWeight: "bolder" }}>
              {headers.map(({ name, sortKey }, i) => (
                <th
                  className="sticky"
                  key={i}
                  onClick={() => sortKey && handleSort(sortKey)}
                  style={{
                    cursor: sortKey ? "pointer" : "default",
                    opacity: sortKey === sortField ? 1 : 0.4,
                  }}
                >
                  {name}{" "}
                  {sortKey && sortField === sortKey
                    ? sortDirection === "asc"
                      ? "↑"
                      : "↓"
                    : ""}
                </th>
              ))}
              {userAuthenticatedContext.scopes?.includes(
                "create:catalogue-item"
              ) && <th></th>}
            </tr>
          </thead>
          <tbody>
            {catalogItemsLoaded ? (
              !catalogItems || catalogItems.length === 0 ? (
                <tr>
                  <td colSpan={9}>
                    <b>No Catalogue items</b>
                  </td>
                </tr>
              ) : (
                catalogItems.map((item, index) => (
                  <CatalogRowComponent
                    catalogItem={item}
                    clients={clients}
                    key={`catalog-item-${index}`}
                  />
                ))
              )
            ) : null}
          </tbody>
        </Table>
        {!catalogItemsLoaded ? <Loading /> : null}
      </Container>
    </ErrorBoundary>
  );
};

export default CatalogTableComponent;
