import { CSSProperties } from "react";
import Carousel, { CarouselProps } from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";

export interface ImageCarouselProps {
  imageUrls: string[];
  autoSlide?: boolean;
  maxWidth?: string;
  rounded?: boolean;
  controlledProps?: {
    selectedIndex: number;
    onSelect: (num: number) => void;
    currentSlideOverlay?: JSX.Element;
  };
  imageCustomStyles?: CSSProperties;
  noAnimation?: boolean;
}

const ImageCarousel = ({
  imageUrls,
  controlledProps,
  maxWidth = "600px",
  autoSlide = true,
  rounded = true,
  noAnimation = false,
  imageCustomStyles,
}: ImageCarouselProps): JSX.Element => {
  let slideInterval: null | number = 2500;
  if (!autoSlide) {
    slideInterval = null;
  }

  if (imageCustomStyles === undefined) {
    imageCustomStyles = {};
  }

  const baseCarouselProps: CarouselProps = {
    style: { maxWidth, margin: "0.5em" },
    variant: "dark",
    interval: slideInterval,
    slide: !noAnimation,
  };

  if (controlledProps) {
    return (
      <Carousel
        activeIndex={controlledProps.selectedIndex}
        onSelect={controlledProps.onSelect}
        {...baseCarouselProps}
      >
        {imageUrls.map((imageUrl, index) => (
          <Carousel.Item key={`imageUrl-${index}`}>
            <Image
              style={imageCustomStyles}
              className="d-block w-100"
              rounded={rounded}
              src={imageUrl}
            />
            {controlledProps.currentSlideOverlay ? (
              <Carousel.Caption
                style={{
                  color: "white",
                  textShadow:
                    "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
                }}
              >
                {controlledProps.currentSlideOverlay}
              </Carousel.Caption>
            ) : null}
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }

  return (
    <Carousel {...baseCarouselProps}>
      {imageUrls.map((imageUrl, index) => (
        <Carousel.Item key={`imageUrl-${index}`}>
          <Image className="d-block w-100" rounded={rounded} src={imageUrl} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
