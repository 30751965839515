import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { updateTaskStatus } from "../../workers/task";
import { UserAuthenticatedContext } from "../profile";
import axios from "axios";
import { Task } from "../../external/task-component/openapi";

interface Props {
  taskId: string;
  setTaskStatus: (status: string) => void;
  setShowAcceptModal: (show: boolean) => void;
  showAcceptModal: boolean;
  task?: Task;
}

const AcceptModal: React.FC<Props> = ({
  taskId,
  setTaskStatus,
  setShowAcceptModal,
  showAcceptModal,
  task,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const userAuthenticatedContext = useContext(UserAuthenticatedContext);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");
  const [catalogueClientItemId, setCatalogueClientItemId] =
    useState<string>("");
  const [
    isValidSaveCatalogueClientItemID,
    setIsValidSaveCatalogueClientItemID,
  ] = useState<boolean>(true);

  useEffect(() => {
    if (task?.type === "catalogueCreateRequest") {
      if (
        task.createdCatalogClientItemId === undefined ||
        task.createdCatalogClientItemId === ""
      ) {
        setIsValidSaveCatalogueClientItemID(false);
      }
    }
  }, [task]);

  const handleCatalogueClientItemId = (text: string) => {
    setCatalogueClientItemId(text);
  };

  const handleAcceptChange = async () => {
    if (userAuthenticatedContext.token) {
      setIsSaving(true);
      try {
        await updateTaskStatus(
          userAuthenticatedContext.token,
          taskId,
          "completed",
          undefined,
          catalogueClientItemId
        );
        setTaskStatus("completed");
        setIsSaving(false);
        setShowAcceptModal(false);
      } catch (error) {
        setErrorMessage(
          "An error occurred. Please try again. If the problem persists, contact support."
        );
        if (axios.isAxiosError(error)) {
          const serverError =
            error.response?.data &&
            typeof error.response.data === "object" &&
            "message" in error.response.data
              ? error.response.data.message
              : "Unknown server error";
          setErrorMessage(`${serverError}`);
          const statusCode = error.response?.status;
          console.error(`Error: ${statusCode} - ${serverError}`);
        } else {
          console.error("An unknown error occurred.");
        }
        setIsError(true);
        setIsSaving(false);
      }
    }
  };

  return (
    <Modal
      show={showAcceptModal}
      onHide={() => {
        // Only allow closing if not in the process of saving
        if (!isSaving) {
          setShowAcceptModal(false);
        }
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Accept</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isError && <div className="alert alert-danger">{errorMessage}</div>}
        {task?.type === "catalogueCreateRequest" &&
        !task.createdCatalogClientItemId ? (
          <Row>
            <Form>
              <Form.Label>
                Please enter the Item ID to accept this change (e.g.{" "}
                <code>CCCABC-XYZ123</code>)
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  disabled={isSaving}
                  onChange={(e) => handleCatalogueClientItemId(e.target.value)}
                />
              </InputGroup>
            </Form>
          </Row>
        ) : (
          <Row>Are you sure you want to accept this change?</Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={isSaving}
          variant="secondary"
          onClick={() => {
            setIsError(false);
            setShowAcceptModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={
            isSaving ||
            isError ||
            (!isValidSaveCatalogueClientItemID &&
              catalogueClientItemId.length === 0)
          }
          variant="primary"
          onClick={async () => {
            await handleAcceptChange();
          }}
        >
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AcceptModal;
