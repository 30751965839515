import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { InventoryItem } from "../../external";
import Table from "react-bootstrap/Table";
import ImageCarousel from "../image-carousel";

interface InventoryInboundModalProps {
  index: number;
  isModalOpen: boolean;
  handleCloseModal: () => void;
  values: InventoryItem[];
}

const InventoryInboundModal: React.FC<InventoryInboundModalProps> = ({
  index,
  isModalOpen,
  handleCloseModal,
  values,
}) => {
  return (
    <Modal show={isModalOpen} onHide={handleCloseModal} fullscreen={true}>
      <Modal.Header closeButton>
        <Modal.Title>Item Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {values[index] && (
          <>
            <Row className="justify-content-md-center">
              {values[index].imageLinks !== undefined &&
              values[index].imageLinks.length > 0 ? (
                <ImageCarousel
                  imageUrls={[...values[index].imageLinks]}
                  maxWidth={"300px"}
                />
              ) : (
                <p>No Images</p>
              )}
            </Row>
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <td style={{ textAlign: "right" }}>Item ID</td>
                  <td>{values[index].id}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Legacy PM ID</td>
                  <td>{values[index].legacyPMID}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Client</td>
                  <td>{values[index].clientId}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Catalogue Item ID</td>
                  <td>{values[index].catalogItemId}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Client Item ID</td>
                  <td>{values[index].clientItemId}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Load Reference ID</td>
                  <td>{values[index].loadReferenceId}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Expected Arrival Date</td>
                  <td>{values[index].expectedArrivalDate}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Pallet</td>
                  <td>{values[index].pallet}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Quantity</td>
                  <td>{values[index].quantity}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Description</td>
                  <td>{values[index].description}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Boxes</td>
                  <td>{values[index].boxes}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Exit</td>
                  <td>{values[index].exit}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Comments</td>
                  <td>{values[index].comments}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Arrived?</td>
                  <td>{values[index].arrivalConfirmed ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Date Arrived</td>
                  <td>{values[index].arrivalDate}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "right" }}>Manual Entry?</td>
                  <td>{values[index].manualEntry ? "Yes" : "No"}</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InventoryInboundModal;
