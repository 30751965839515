import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useForm, useFieldArray } from "react-hook-form";
import { CatalogItem, InventoryItem } from "../../external";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
import { DefaultTestRefurbStepsConfiguration } from "../gradingandrefurb/refurb-steps/configuration";
import { GradingSummaryTable } from "../gradingandrefurb/refurb-steps/refurb-summary-table";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { createWorkerFactory } from "@shopify/react-web-worker";
import { UserAuthenticatedContext } from "../profile";
import Loading from "../loading";
import axios from "axios";
import { useDebugMode } from "../../hooks/useDebugMode";
import { useCircleAuthScopes } from "../../hooks/useCircleAuthScopes";
import { GradingItemState } from "../../external/inventory-component/grading-types";
import { useCircleDarkMode } from "../../hooks/useCircleDarkMode";
import { Accordion } from "react-bootstrap";
import { formatDateTimeFromTimestamp } from "../../helpers/datetime";
export interface InventoryItemComponentProps {
  inventoryItem: InventoryItem;
  viewOnly?: boolean;
  catalogItem?: CatalogItem;
  persist: (newItem: InventoryItem) => Promise<any>;
}

const separatorColours = [
  "cornflowerblue",
  "yellow",
  "green",
  "red",
  "limegreen",
  "orange",
  "blue",
  "indigo",
  "darkgreen",
  "darkmagenta",
];

const createInventoryItemsWorker = createWorkerFactory(
  () => import("../../workers/inventory")
);

const InventoryItemComponent = ({
  inventoryItem,
  catalogItem,
  viewOnly,
}: InventoryItemComponentProps): JSX.Element => {
  const {
    register,
    // reset,
    control,
    // getValues,
    // formState: { touchedFields, errors, isDirty, isValid },
  } = useForm<InventoryItem>({
    mode: "onChange",
    values: inventoryItem,
  });

  const navigate = useNavigate();

  const userAuthenticatedContext = useContext(UserAuthenticatedContext);

  const storefrontsControl = useFieldArray({
    control,
    name: "storefronts",
  });
  const onSubmit = () => {};
  const inventoryWorker = createInventoryItemsWorker();

  const [gradingAndRefurbEntries, setGradingAndRefurbEntries] = useState<
    GradingItemState[]
  >([]);

  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [isLoadingGrading, setIsLoadingGrading] = useState<boolean>(false);
  const [anyGradingInProgress, setAnyGradingInProgress] =
    useState<boolean>(false);
  const [canStartNewRefurb, setCanStartNewRefurb] = useState<boolean>(false);
  const debugMode = useDebugMode();
  const authScopes = useCircleAuthScopes();
  const darkMode = useCircleDarkMode();
  useEffect(() => {
    if (!userAuthenticatedContext?.token) {
      console.info("No token yet");
      return;
    }
    if (hasLoaded || isLoadingGrading) {
      return;
    }
    setIsLoadingGrading(true);

    inventoryWorker
      .getGradingEntries(inventoryItem.id, userAuthenticatedContext.token)
      .then((result) => {
        setGradingAndRefurbEntries(result);
        setAnyGradingInProgress(result.some((r) => !r.completed));
        setHasLoaded(true);
        setIsLoadingGrading(false);
      })
      .catch((e) => {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 404) {
            console.info("No grading entries found for this item");
            setGradingAndRefurbEntries([]);
            setAnyGradingInProgress(false);
            setHasLoaded(true);
            setIsLoadingGrading(false);
          }
        } else {
          console.error(e);
        }
      });
  }, [
    inventoryItem,
    catalogItem,
    userAuthenticatedContext.token,
    inventoryWorker,
    hasLoaded,
    isLoadingGrading,
  ]);

  useEffect(() => {
    setCanStartNewRefurb(
      gradingAndRefurbEntries.length === 0 || !anyGradingInProgress
    );
  }, [gradingAndRefurbEntries, anyGradingInProgress]);

  const GradingSection = () => {
    if (!authScopes.includes("read:grading-item")) {
      return null;
    }

    if (!hasLoaded) {
      return <Loading />;
    }

    if (!catalogItem) {
      return (
        <i style={{ color: darkMode ? "lightcoral" : "rebeccapurple" }}>
          No or invalid catalogue entry assigned to item
        </i>
      );
    }

    const getHeaderText = (g: GradingItemState) => {
      const getColor = (g: GradingItemState) => {
        if (g.completed) {
          return darkMode ? "lightgreen" : "green";
        }
        return darkMode ? "lightcoral" : "red";
      };
      const startedText = `Started ${formatDateTimeFromTimestamp(
        g.createdAtTimestamp
      )}`;
      const updatedText = `, last updated ${formatDateTimeFromTimestamp(
        g.lastUpdatedTimestamp
      )}`;
      const gradedText = g.warehouseGrade ? `Grade ${g.warehouseGrade}` : "";
      return (
        <>
          <b style={{ color: getColor(g), marginRight: "0.5em" }}>
            {g.completed ? "Complete" : "Incomplete"}
          </b>
          {gradedText ? <b>{gradedText}</b> : null}
          <i>{startedText}</i>
          <i>{updatedText}</i>
        </>
      );
    };

    return (
      <>
        <h5>Grading</h5>
        {canStartNewRefurb ? (
          <Container>
            <Button
              className="mt-2 mb-2 w-100"
              size="lg"
              variant="success"
              disabled={!authScopes.includes("create:grading-item")}
              onClick={() =>
                navigate(
                  `/gradingandrefurb/${inventoryItem.id}/new?itemId=${inventoryItem.id}&clientId=${catalogItem.clientId}&sku=${catalogItem.itemId}`
                )
              }
            >
              Start New Grading Session{" "}
              {!authScopes.includes("create:grading-item")
                ? "(Insufficient Permissions)"
                : ""}
            </Button>
          </Container>
        ) : null}
        {gradingAndRefurbEntries && gradingAndRefurbEntries.length > 0 ? (
          <>
            <b>Previous Grading Sessions</b>
            <Accordion>
              {gradingAndRefurbEntries.map((g, i) => (
                <Accordion.Item eventKey={`${i}`} key={i}>
                  <Accordion.Header>{getHeaderText(g)}</Accordion.Header>
                  <Accordion.Body>
                    <Container key={i}>
                      <InventoryItemRefurbInfoComponent
                        inventoryItem={inventoryItem}
                        navigate={navigate}
                        catalogItem={catalogItem}
                        refurbIdentifier={g.id}
                        entry={g}
                      />
                    </Container>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Form noValidate onSubmit={onSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Item ID</Form.Label>
          <Form.Control
            {...register("id", {
              disabled: true,
            })}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Client ID</Form.Label>
          <Form.Control {...register("clientId", { disabled: true })} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Legacy PM ID</Form.Label>
          <Form.Control {...register("legacyPMID", { disabled: viewOnly })} />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Catalogue Item ID</Form.Label>
          <Form.Control
            {...register("catalogItemId", { disabled: viewOnly })}
          />
        </Form.Group>
        {/* <Form.Group className="mb-3">
          <Form.Label>Grade</Form.Label>
          <Form.Control {...register("warehouseGrade", { disabled: true })} />
        </Form.Group> */}
        <Form.Group className="mb-3">
          <Form.Label>Storefronts</Form.Label>
          <Button
            size="sm"
            variant="success"
            className={viewOnly ? `d-none` : ""}
            style={{ marginLeft: "1em" }}
            onClick={() =>
              storefrontsControl.append({
                isListed: false,
                itemId: "",
                storefrontId: "amazon",
              })
            }
          >
            Add Storefront <PlusCircle />
          </Button>
          {storefrontsControl.fields.length === 0 ? (
            <>
              <br />
              <i>No storefronts configured</i>
            </>
          ) : (
            storefrontsControl.fields.map((field, index) => (
              <Container
                className="mb-2"
                style={{ borderLeft: `2px solid ${separatorColours[index]}` }}
              >
                <b>Storefront {index + 1}</b>{" "}
                <Button
                  variant="danger"
                  size="sm"
                  className={viewOnly ? `d-none` : ""}
                  style={{ marginLeft: "1em" }}
                  onClick={() => storefrontsControl.remove(index)}
                >
                  <DashCircle />
                </Button>
                <br />
                <Form.Label>Storefront</Form.Label>
                <Form.Select
                  {...register(`storefronts.${index}.storefrontId`, {
                    disabled: viewOnly,
                  })}
                  className="mb-2"
                >
                  <option>Select a storefront...</option>
                  {/**  TODO: Add options for other storefronts */}
                  {["amazon", "ebay", "etsy"].map((storefront) => (
                    <option value={storefront} key={storefront}>
                      {storefront}
                    </option>
                  ))}
                </Form.Select>
                <Form.Label>
                  Storefront ID/Listing ID/Listing Reference
                </Form.Label>
                <Form.Control
                  {...register(`storefronts.${index}.itemId`, {
                    disabled: viewOnly,
                  })}
                  className="mb-2"
                />
                <Form.Label>Is Listed?</Form.Label>
                <Form.Check
                  {...register(`storefronts.${index}.isListed`, {
                    disabled: viewOnly,
                  })}
                  className="mb-2"
                />
              </Container>
            ))
          )}
        </Form.Group>
      </Form>
      <hr />
      <GradingSection />
      {debugMode ? (
        <Container>
          <h3>Debug</h3>
          <Row>
            <Col>
              <code>Catalog Item</code>{" "}
              <pre>{JSON.stringify(catalogItem, null, 2)}</pre>
            </Col>
            <Col>
              <code>hasLoaded</code> {JSON.stringify(hasLoaded)}
            </Col>
            <Col>
              <code>isLoadingGrading</code> {JSON.stringify(isLoadingGrading)}
            </Col>
          </Row>
        </Container>
      ) : null}
    </>
  );
};

interface InventoryItemRefurbInfoComponentProps {
  inventoryItem: InventoryItem;
  refurbIdentifier: string;
  entry: GradingItemState;
  catalogItem: CatalogItem;
  navigate: NavigateFunction;
}

export const InventoryItemRefurbInfoComponent = ({
  inventoryItem,
  refurbIdentifier,
  catalogItem,
  entry,
  navigate,
}: InventoryItemRefurbInfoComponentProps) => {
  const startedAt = new Date(entry.createdAtTimestamp);
  const lastUpdated = new Date(entry.lastUpdatedTimestamp);
  const authScopes = useCircleAuthScopes();
  return (
    <Container>
      <Row>
        <b>
          Grading session {refurbIdentifier} -{" "}
          {entry.completed ? <b>Complete</b> : <i>Incomplete</i>}
        </b>
      </Row>
      <Row sm={1}>
        <Col>
          <b>Started at</b> {startedAt.toLocaleTimeString()}
          {", "}
          {startedAt.toLocaleDateString()}
        </Col>
        <Col>
          <b>Last updated at</b> {lastUpdated.toLocaleTimeString()}
          {", "}
          {lastUpdated.toLocaleDateString()}
        </Col>
      </Row>
      {!entry.completed ? (
        <Row>
          <Col>
            <Button
              className="mt-2 mb-2 w-100"
              size="lg"
              disabled={!authScopes.includes("edit:grading-item")}
              onClick={() => {
                navigate(
                  `/gradingandrefurb/${inventoryItem.id}/${refurbIdentifier}`
                );
              }}
            >
              Continue Grading{" "}
              {!authScopes.includes("edit:grading-item")
                ? "(Insufficient Permissions)"
                : ""}
            </Button>
          </Col>
        </Row>
      ) : (
        <Row>
          <GradingSummaryTable
            refurbConfiguration={DefaultTestRefurbStepsConfiguration(
              catalogItem
            )}
            refurbIdentifier={refurbIdentifier}
          ></GradingSummaryTable>
        </Row>
      )}
    </Container>
  );
};

export default InventoryItemComponent;
