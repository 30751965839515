import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/loading";
import { useLocalStorage } from "usehooks-ts";
import GoogleAppsScriptIframe from "../../components/google-apps-script-iframe";

const tabContents = [
  {
    title: "Pallet Scanner",
    key: "pallet-scanner",
    url: "https://script.google.com/macros/s/AKfycbyUQC9u4odUPVRv2IbNZ2di2ktmKDZLzGTYmlyBj66zk-K7AM4b-OMkXVh2LhV0K8YJPA/exec",
  },
  {
    title: "Stock Images",
    key: "pm-image",
    url: "https://script.google.com/macros/s/AKfycbySLpoFk7HJ1cYEAz7BKts0xVVQlAgAxAC90wC8M1sh1gM6Uyx9R5EId3wIKipdraZw/exec",
  },
];

const tabHeight = 1200;
const OperationsExternalPages = () => {
  const [key, setKey] = useLocalStorage(
    "cc-circle-operationsselectedtab",
    tabContents[0].key
  );
  return (
    <Container>
      <Tabs
        id="operations-tab-container"
        activeKey={key}
        variant="pills"
        onSelect={(k) => setKey(k!)}
      >
        {tabContents.map((report, i) => (
          <Tab eventKey={report.key} key={`report${i}`} title={report.title}>
            <GoogleAppsScriptIframe
              src={report.url}
              title={report.title}
              style={{
                height: tabHeight,
                margin: "0.5em",
              }}
            />
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
};

export default withAuthenticationRequired(OperationsExternalPages, {
  onRedirecting: () => <Loading />,
});
